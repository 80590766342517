import { useState, useEffect } from 'react';
import { PERMISSION, NO_CHECK_ACCESS_ROUTES } from 'utils/constant';
import { useIsRetailer } from './useAuth';
import { useStore } from './useStore';

const getAccessRoutes = (routes, permissions) =>
  routes?.filter(
    (route) =>
      NO_CHECK_ACCESS_ROUTES.includes(route?.path) ||
      permissions?.find(
        (permission) =>
          PERMISSION[route?.key]?.functionName === permission?.functionName ||
          route?.routes?.some(
            (nestedRoute) =>
              PERMISSION[nestedRoute?.key]?.functionName ===
              permission?.functionName,
          ),
      )?.canAccess,
  );

const useAccessRoutes = (routes) => {
  const [{ user }] = useStore();
  const isRetailer = useIsRetailer();

  const [accessRoutes, setAccessRoutes] = useState([]);
  useEffect(() => {
    if (user?.permissions) {
      const newAccessRoutes = getAccessRoutes(routes, user?.permissions)?.map(
        (nestedRoute) => ({
          ...nestedRoute,
          routes: getAccessRoutes(nestedRoute?.routes, user?.permissions),
        }),
      );
      setAccessRoutes(newAccessRoutes);
    }
  }, [user?.permissions, isRetailer]);

  return [accessRoutes];
};

export default useAccessRoutes;
